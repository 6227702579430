import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>Unearthed Arcana</h2>
    <h3><a id="legendary-weapons"></a>LEGENDARY WEAPONS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79172.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a>
This variant is a simple rule system that allows the abilities of weapons
to improve along with those of their wielders. A character wielding a
legendary weapon � whether that weapon is a sword, axe, bow, or even a magic
staff � is eligible for one of the scion prestige classes presented below.
As long as the wielder does not adopt the prestige class, the legendary
weapon is simply a magic item with a small enhancement bonus (usually +1 or
+2, or sometimes as high as +3 for truly powerful legendary weapons). But
by taking levels in the prestige class, a character can unlock and utilize
the weapon�s more advanced powers. Commonly, the wielder gains access to a
suite of weapon-appropriate feats, special abilities tied to the weapon�s
purpose, or even magical enhancements to the character�s own skills and
abilities.
    <p>
In every case, the scion prestige class associated with a particular
weapon defines which of its abilities the wielder is eligible to benefit
from. The descriptions of sample legendary weapons later in this section
detail the specific abilities of each weapon. Thus, every scion is
different, even with only four variations of the prestige class to choose
from.
    </p>
    <h5><a id="scions"></a>
SCIONS
    </h5>
Scions come in as many varieties as there are legendary items, but each
one falls into one of four distinct groupings based on the intended
wielder of the item. Each sample weapon described in this section is tied
to one of the following prestige classes (and the weapon description
immediately follows the prestige class description).
    <p><b>Battle Scion:</b>
This prestige class is for the wielders of legendary weapons made for the
hands of fighters, barbarians, rangers, monks, and the occasional paladin.
    </p>
    <p><b>Faith Scion:</b>
This prestige class is meant for characters who wield legendary weapons of
divine power for clerics, druids, and paladins.
    </p>
    <p><b>Spell Scion:</b>
This class is for characters who wield legendary weapons designed for use
by arcane spellcasters, such as wizards, sorcerers, and sometimes bards.
    </p>
    <p><b>Swift Scion:</b>
This class is for those who wield legendary weapons that make use of or
improve the wielder�s stealth, speed, or dexterity (in the general sense).
Rogues are excellent candidates for this class, as are bards, rangers, and
the occasional monk.
    </p>
    <h6><a id="rules-and-restrictions"></a>
Rules and Restrictions
    </h6>
None of the scion prestige classes grant a character any new weapon or
armor proficiencies. Thus, it is entirely possible for a scion to be not
proficient with his legendary weapon unless he independently takes the
necessary weapon proficiency feat.
    <p>
In addition to meeting the prerequisites for the appropriate prestige
class (which are dictated by the weapons themselves), a character wishing
to adopt a scion class must also abide by certain other rules and
restrictions.
    </p>
    <p><b><a id="weapon-specialization"></a>Weapon Specialization:</b>
A scion who does not meet the fighter level prerequisite for the <a style={{
        "color": "#579eb6"
      }} href="featsAll.html#weapon-specialization">Weapon
Specialization</a> feat may still select that feat, but only for the type of
weapon (such as martial or exotic) that his legendary weapon is.
    </p>
    <p>
A scion who qualifies for the appropriate Weapon Specialization feat
normally (that is, who has all the normal prerequisites for the feat) may
select and apply the feat to any type of weapon.
    </p>
    <p><b><a id="losing-a-legendary-weapon"></a>Losing a Legendary Weapon:</b>
Legendary weapons should never become permanently lost, for fate has a way
of bringing them back to their rightful wielders. On a more practical
level, the loss of a legendary weapon means that the character who has
invested levels in the associated prestige class is denied most of the
class�s special abilities until the weapon is regained, because the
abilities are specifically tied to the weapon. Thus, as the GM, you must
try to ensure that the weapon and its wielder do not stay separated for
long � unless, of course, the purpose of the adventure is to recover the
weapon. In most cases, the weapon should turn up at the end of an
encounter, or should be near enough at hand that the wielder need only
make a reasonable effort to reclaim it.
    </p>
    <p><b><a id="destroying-a-legendary-weapon"></a>Destroying a Legendary Weapon:</b>
Each legendary weapon has its own hardness and hit points. Treat the item
as if it had a greater hardness and more hit points based on its maximum
possible enhancement
bonus, even if the wielder has not yet qualified to benefit from that
high a bonus.
    </p>
    <p>
That said, under ordinary circumstances, legendary weapons do not break:
Any attempt to sunder such an item automatically fails, and the weapon is
treated as having immunity to all effects that could otherwise destroy it
(such as a <a style={{
        "color": "#579eb6"
      }} href="spellsDtoE.html#disintegrate">disintegrate</a> spell, a dragon�s breath weapon, and so on).
    </p>
    <p>
If an attacker is foolish enough to attempt to sunder a legendary weapon,
the combatants should still make the opposed attack rolls, however.
If the scion wins, the scion may immediately deal damage to the attacker�s
weapon as though the scion had initiated the attack.
    </p>
    <p>
The only exception to this rule is if the person attacking the legendary
weapon is herself wielding a legendary weapon. In this case, the attacker
may deal damage to the defender�s weapon if she wins the opposed attack roll. However,
the defender also immediately makes his own sunder attempt against the
attacker�s legendary weapon. If the defender wins this second opposed
roll, he may deal damage to the attacker�s weapon � even if his own weapon
was damaged or destroyed by the attacker.
    </p>
    <p>
Repairing a broken legendary weapon should never be easy. It should be the
result of an epic quest, perhaps involving aid from other planes, arduous
rituals, and perilous voyages.
    </p>
    <p><b><a id="gaining-additional-legendary-weapons"></a>Gaining Additional Legendary Weapons:</b>
If the wielder of a legendary weapon somehow gains another legendary
weapon linked to the same scion prestige class, she may only use the
weapon as would a character with no levels in the weapon�s associated
prestige class, and she may not transfer the benefits of her scion levels
to the new weapon. If she were to acquire a weapon linked to a different
scion prestige class, she could begin advancing levels in the scion
prestige class for that weapon, assuming that she meets the requirements.
    </p>
    <p>
The level of commitment that a legendary weapon demands from its wielder
does not allow her to split her attention between two or more such weapons
simultaneously. Thus, the owner of two legendary weapons must choose which
commitment she wishes to focus on with each new character level, by virtue
of her prestige class selection.
    </p>
    <p><b><a id="ex-scions"></a>Ex-Scions:</b>
A wielder who no longer meets the prerequisites of his scion prestige
class loses the ability to access the special abilities of his legendary
weapon (as noted in the sample weapons below) that correspond to actual
scion levels. He retains the basic features of the prestige class as given
in its description (base attack bonus, base save bonuses, and even
spellcaster levels, when appropriate), but the weapon supplies only the
magical abilities it would grant to any wielder. Likewise, a scion who no
longer possesses his legendary weapon (because of loss, destruction,
theft, or some other reason) loses all the benefits that the weapon
granted (though he still retains the basic features of the class). An
ex-scion may not progress in the prestige class until he corrects the
problem, either by once again meeting the requirements of the class or by
regaining the weapon.</p>
    <p>
This restriction does not prevent a scion from taking levels in another
class, or from using the abilities already gained as a scion while he does
so. Unless the new class in some way violates the scion prerequisites
(such as by requiring a different alignment), or the weapon�s
description specifies penalties for advancing in another class, then the
only drawback to pursuing a different class is that the scion is not
gaining new powers from the weapon.
    </p>
    <h5><a id="battle-scion"></a>
BATTLE SCION
    </h5>
A battle scion has earned the right to fight with a particular legendary
weapon, either because the weapon is an ancestral heirloom or because he
upholds the principles for which the weapon was originally created. While
characters of any class can eventually qualify for the battle scion class,
those who wield weapons professionally � fighters, barbarians, rangers, and
paladins � are ideal candidates.
    <p>
No two battle scions are identical, by virtue of the weapons that grant
them access to this prestige class. One scion might wield an heirloom
weapon that provides him with the abilities needed to uphold the family�s
honor. Another could be the scourge of an evil temple, using his legendary
sword to prove that might does indeed make right. Yet another could be
wielding an axe, hammer, or staff dedicated to his profession, or to his
race, with abilities that draw on the strengths thereof.
    </p>
    <p>
For all the powers his sword grants him, a battle scion isn�t terribly
different from any other fighter, barbarian, ranger, or paladin who uses
his share of the treasure to upgrade his favorite weapon every so often.
What really sets him apart is the fact that his weapon is leading him
toward a particular destiny, if only by shaping his future advancement
choices.
    </p>
    <p><b>Hit Die:</b>
d10.
    </p>
    <h6>
Requirements
    </h6>
To qualify to become a battle scion, a character must fulfill the criteria
given in his particular weapon�s entry. (For an example, see
    <a style={{
      "color": "#579eb6"
    }} href="#drakeblade">Drakeblade</a>, below.)
    <h6>
Class Skills
    </h6>
The battle scion�s class skills (and the key ability for each skill) are
    <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#climb">Climb</a> (Str), <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#craft">Craft</a> (Int), <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#handle-animal">Handle Animal</a> (Cha), <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#intimidate">Intimidate</a> (Cha), <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#jump">Jump</a>
(Str), <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#ride">Ride</a> (Dex), and <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#swim">Swim</a> (Str).
    <p><b>Skill Points at Each Level:</b>
2 + Int modifier.
    </p>
    <b><a id="table-the-battle-scion"></a>Table: The Battle Scion</b>
    <table cellSpacing="0" style={{
      "width": "50%"
    }}>
      <tr>
        <th>
Level
        </th>
        <th>
Base
Attack
Bonus
        </th>
        <th>
Fort
Save
        </th>
        <th>
Ref
Save
        </th>
        <th>
Will
Save
        </th>
        <th>
Special
        </th>
      </tr>
      <tr className="odd-row">
        <td align="center">
1st
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+0
        </td>
        <td align="center">
+0
        </td>
        <td>
Bonus feat
        </td>
      </tr>
      <tr>
        <td align="center">
2nd
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+0
        </td>
        <td align="center">
+0
        </td>
        <td>
�
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
3rd
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+1
        </td>
        <td>
�
        </td>
      </tr>
      <tr>
        <td align="center">
4th
        </td>
        <td align="center">
+4
        </td>
        <td align="center">
+4
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+1
        </td>
        <td>
Bonus feat
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
5th
        </td>
        <td align="center">
+5
        </td>
        <td align="center">
+4
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+1
        </td>
        <td>
�
        </td>
      </tr>
      <tr>
        <td align="center">
6th
        </td>
        <td align="center">
+6
        </td>
        <td align="center">
+5
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+2
        </td>
        <td>
�
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
7th
        </td>
        <td align="center">
+7
        </td>
        <td align="center">
+5
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+2
        </td>
        <td>
Bonus feat
        </td>
      </tr>
      <tr>
        <td align="center">
8th
        </td>
        <td align="center">
+8
        </td>
        <td align="center">
+6
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+2
        </td>
        <td>
�
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
9th
        </td>
        <td align="center">
+9
        </td>
        <td align="center">
+6
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+3
        </td>
        <td>
�
        </td>
      </tr>
      <tr>
        <td align="center">
10th
        </td>
        <td align="center">
+10
        </td>
        <td align="center">
+7
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+3
        </td>
        <td>
Bonus feat
        </td>
      </tr>
    </table>
    <h6>
Class Features
    </h6>
The following are class features of the battle scion prestige class. A
legendary weapon associated with the class provides a unique set of
additional special abilities, which are detailed in the weapon�s
description. (For an example, see <a style={{
      "color": "#579eb6"
    }} href="#drakeblade">Drakeblade</a>, below.) These
abilities accrue in addition to those already noted in the Special column
on <a style={{
      "color": "#579eb6"
    }} href="#table-the-battle-scion">Table: The Battle Scion</a>. However, because those abilities are functions
of the weapon rather than the wielder, they are lost if the legendary
weapon that granted them is lost. The features mentioned on Table: The
Battle Scion are retained even if the weapon is lost.
    <p><b>Bonus Feats:</b>
At 1st level, a battle scion receives a bonus feat. Thereafter, he gains
an additional bonus feat at 4th, 7th, and 10th level. These bonus feats
must be drawn from the <a style={{
        "color": "#579eb6"
      }} href="featsFtb.html">fighter
bonus feat</a> list. A battle scion must still meet all prerequisites to
take a bonus feat.
    </p>
    <h6><a id="drakeblade"></a>
Drakeblade
    </h6>
Reputedly carved from the fang of a great wyrm red dragon, the bastard
sword known as <i>Drakeblade</i> has served many masters over the
centuries, from bloodthirsty warlords to treasure-seeking adventurers. It
is a legendary weapon that provides many benefits to its wielder, but most
of the benefits apply only if the wielder has levels in the battle scion
prestige class.
    <p><b>Requirements:</b>
Any character can wield <i>Drakeblade</i> as a +2 bastard sword and also
gain the benefit of the bravery special ability described below. A battle
scion who wields <i>Drakeblade</i> gains additional special abilities if
the character fulfills the following criteria.
    </p>
    <p><i>Base Attack Bonus:</i>
+9.
    </p>
    <p><i>Skills:</i>
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#knowledge">Knowledge</a> (arcana) 6 ranks.
    </p>
    <p><i>Feats:</i>
      <a style={{
        "color": "#579eb6"
      }} href="featsAll.html#improved-critical">Improved Critical</a> (bastard sword), <a style={{
        "color": "#579eb6"
      }} href="featsAll.html#iron-will">Iron Will</a>.
    </p>
    <p><b>Attributes:</b>
      <i>Drakeblade</i> has the following attributes.
    </p>
    <p><i>Hardness/Hit Points:</i>
20/55.
    </p>
    <p><i>Caster Level:</i>
15th.
    </p>
    <p><i>Value:</i>
To any character other than a battle scion who meets the above
requirements, <i>Drakeblade</i> appears to be worth as much as a Medium
+2 bastard sword that grants the bravery special ability (market price
14,335 gp).
    </p>
    <b><a id="table-abilities-granted-by-drakeblade"></a>Table: Abilities Granted By Drakeblade</b>
    <table cellSpacing="0" style={{
      "width": "40%"
    }}>
      <tr>
        <th>
Battle Scion
Level
        </th>
        <th align="left">
Ability Gained
        </th>
      </tr>
      <tr className="odd-row">
        <td align="center">
�
        </td>
        <td>
Bravery
        </td>
      </tr>
      <tr>
        <td align="center">
1st
        </td>
        <td>
Bane (dragons)
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
2nd
        </td>
        <td>
Resistance to energy 10
        </td>
      </tr>
      <tr>
        <td align="center">
3rd
        </td>
        <td>
Enhancement bonus +3
(+5 against dragons)
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
4th
        </td>
        <td>
Intimidating presence
        </td>
      </tr>
      <tr>
        <td align="center">
5th
        </td>
        <td>
Resistance to energy 20
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
6th
        </td>
        <td>
Enhancement bonus +4
(+6 against dragons)
        </td>
      </tr>
      <tr>
        <td align="center">
7th
        </td>
        <td>
Dodge bonus
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
8th
        </td>
        <td>
Resistance to energy 30
        </td>
      </tr>
      <tr>
        <td align="center">
9th
        </td>
        <td>
Frightful presence
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
10th
        </td>
        <td>
Enhancement bonus +5
(+7 against dragons)
        </td>
      </tr>
    </table>
    <p><b>Special Abilities:</b>
      <i>Drakeblade</i> provides a number of special abilities to its wielder,
depending on the character�s class level in the battle scion prestige
class.
    </p>
    <p><i>Initial Abilities:</i>
When first acquired, <i>Drakeblade</i> functions as a +2 bastard sword.
Anyone who wields it, whether a battle scion or not, also benefits from
the bravery ability (see below).
    </p>
    <p><i>Bravery (Ex):</i>
The wielder of <i>Drakeblade</i> has immunity to a dragon�s frightful
presence ability. All allies within 30 feet of the wielder gain a +4
morale bonus on saves
against the frightful presence of dragons.
    </p>
    <p><i>Bane (Dragons) (Su):</i>
When wielded by a battle scion, <i>Drakeblade</i> gains the <a style={{
        "color": "#579eb6"
      }} href="magicItemsAW.html#bane">bane</a>
(dragons) special ability. Its enhancement bonus is treated as
2 points higher when attacking a dragon, and it deals an extra 2d6 points
of damage on a successful strike against a dragon.
    </p>
    <p><b>Resistance to Energy (Su):</b>
Once per day, when a battle scion of 2nd level or higher wielding
      <i>Drakeblade</i> is dealt at least 1 point of damage from any type of
energy (acid, cold, electricity, fire, or sonic), the scion gains
      <a style={{
        "color": "#579eb6"
      }} href="monsterTypes.html#resitance-to-energy">resistance</a> 10 to that form of energy for 1 hour thereafter (including
against the damage that activated the ability). For example, if the scion
were engulfed in a <a style={{
        "color": "#579eb6"
      }} href="spellsFtoG.html#fireball">fireball</a>, the resistance would immediately take effect,
reducing the damage taken by the fireball (and any other source of fire
damage for the next hour) by 10 points.
    </p>
    <p>
Once the energy type is determined, it cannot be changed for the current
use of the ability. If two or more types of energy strike the scion
simultaneously, he may select the energy type against which his resistance
protects.
    </p>
    <p>
When a battle scion attains 5th level, the resistance granted by this
ability increases to 20 points. At 8th level, it increases to 30 points.
    </p>
    <p><i>Intimidating Presence (Ex):</i>
A battle scion of 4th level or higher wielding <i>Drakeblade</i> may add
his class level as a bonus on <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#intimidate">Intimidate</a> checks made against dragons (or
one-half his class level as a bonus on Intimidate checks made against
nondragons).
    </p>
    <p><i>Dodge Bonus> (Ex):</i>
At 7th level and higher, a battle scion wielding <i>Drakeblade</i> gains
a +2 dodge bonus to Armor
Class against creatures at least two size categories larger than himself.
    </p>
    <p><i>Frightful Presence (Ex):</i>
A battle scion of 9th level or higher wielding <i>Drakeblade</i> can
unsettle foes with his mere presence. This ability takes effect
automatically whenever the scion attacks or charges. Creatures within a
radius of 60 feet are subject to the effect if they have fewer Hit Dice
than the scion�s character level.
    </p>
    <p>
A potentially affected creature that succeeds on a Will save (DC 10 + class level
+ scion�s Cha modifier) remains immune to that scion�s frightful presence
for one day. On a failure, creatures with 4 or less HD become <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#panicked">panicked</a> for
4d6 rounds and those with 5 or more HD become <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#shaken">shaken</a> for 4d6 rounds. Even
dragons can be affected by the scion�s frightful presence.
    </p>
    <h5><a id="faith-scion"></a>
FAITH SCION
    </h5>
A faith scion wields his legendary blade in the name of his deity � or, in
the case of a druid, in the service of nature. A legendary weapon usually
comes into the hands of a faith scion either as a gift from his religious
order or as a blessing from his deity. Because of the divine nature of
these weapons, clerics, druids and paladins are ideally suited for this
prestige class, though other classes can also qualify.
    <p>
A divinely influenced legendary weapon should be rare and unique.
Certainly no deity would create multiple copies of one weapon as a matter
of course, and few religious orders can afford to do so. Thus, each weapon
should grant different abilities, except in special cases. For example,
duplicate weapons might be created especially to combat the enemies of a
church, or perhaps to destroy (or create) undead. A divine legendary sword
of an order devoted to healing might even be less a weapon than an
instrument for easing the suffering of others.
    </p>
    <p>
A faith scion usually has high status within his religious order. His
possession of a legendary weapon implies that he has the favor of his
deity, or that he is more in tune with nature than others of his order, in
the case of a druid. Depending on the alignment of his deity, though, his
ownership of the weapon could be construed as an invitation to try to take
it from him.
    </p>
    <p><b>Hit Die:</b>
d8.
    </p>
    <h6>
Requirements
    </h6>
To qualify to become a faith scion, a character must fulfill the criteria
given in his particular weapon�s entry. (For an example, see <a style={{
      "color": "#579eb6"
    }} href="#hammer-of-the-dwarffather">Hammer of
the Dwarffather</a>, below.)
    <h6>
Class Skills
    </h6>
The faith scion�s class skills (and the key ability for each skill) are
    <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#concentration">Concentration</a> (Con), <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#craft">Craft</a> (Int), <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#diplomacy">Diplomacy</a> (Cha), <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#heal">Heal</a> (Wis), <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#knowledge">Knowledge</a>
(arcana) (Int), <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#knowledge">Knowledge</a> (nature) (Int), <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#knowledge">Knowledge</a> (religion) (Int),
    <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#profession">Profession</a> (Wis), <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#ride">Ride</a> (Dex), <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#spellcraft">Spellcraft</a> (Int), and <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#survival">Survival</a> (Wis).
    <p><b>Domains and Class Skills:</b>
A cleric who gains additional class skills from his domain can treat those
skills as class skills for the faith scion class.
    </p>
    <p><b>Skill Points at Each Level:</b>
2 + Int modifier.
    </p>
    <b><a id="table-the-faith-scion"></a>Table: The Faith Scion</b>
    <table cellSpacing="0" style={{
      "width": "70%"
    }}>
      <tr>
        <th>
Level
        </th>
        <th>
Base
Attack
Bonus
        </th>
        <th>
Fort
Save
        </th>
        <th>
Ref
Save
        </th>
        <th>
Will
Save
        </th>
        <th align="left">
Spells per Day/Spells Known
        </th>
      </tr>
      <tr className="odd-row">
        <td align="center">
1st
        </td>
        <td align="center">
+0
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+0
        </td>
        <td align="center">
+2
        </td>
        <td>
+1 level of existing divine spellcasting class
        </td>
      </tr>
      <tr>
        <td align="center">
2nd
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+0
        </td>
        <td align="center">
+3
        </td>
        <td>
+1 level of existing divine spellcasting class
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
3rd
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+3
        </td>
        <td>
+1 level of existing divine spellcasting class
        </td>
      </tr>
      <tr>
        <td align="center">
4th
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+4
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+4
        </td>
        <td>
+1 level of existing divine spellcasting class
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
5th
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+4
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+4
        </td>
        <td>
+1 level of existing divine spellcasting class
        </td>
      </tr>
      <tr>
        <td align="center">
6th
        </td>
        <td align="center">
+4
        </td>
        <td align="center">
+5
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+5
        </td>
        <td>
+1 level of existing divine spellcasting class
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
7th
        </td>
        <td align="center">
+5
        </td>
        <td align="center">
+5
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+5
        </td>
        <td>
+1 level of existing divine spellcasting class
        </td>
      </tr>
      <tr>
        <td align="center">
8th
        </td>
        <td align="center">
+6
        </td>
        <td align="center">
+6
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+6
        </td>
        <td>
+1 level of existing divine spellcasting class
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
9th
        </td>
        <td align="center">
+6
        </td>
        <td align="center">
+6
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+6
        </td>
        <td>
+1 level of existing divine spellcasting class
        </td>
      </tr>
      <tr>
        <td align="center">
10th
        </td>
        <td align="center">
+7
        </td>
        <td align="center">
+7
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+7
        </td>
        <td>
+1 level of existing divine spellcasting class
        </td>
      </tr>
    </table>
    <h6>
Class Features
    </h6>
The following are class features of the faith scion prestige class. A
legendary weapon associated with the class provides a unique set of
additional special abilities, which are detailed in the weapon�s
description. (For an example, see the <i>Hammer of the Dwarffather</i>,
below.) These abilities accrue in addition to those already noted on
    <a style={{
      "color": "#579eb6"
    }} href="#table-the-faith-scion">Table: The Faith Scion</a>. However, because those abilities are functions of
the weapon rather than the wielder, they are lost if the legendary weapon
that granted them is lost. The features mentioned on Table: The Faith
Scion are retained even if the weapon is lost.
    <p><b>Spells per Day/Spells Known:</b>
At each faith scion level, a character gains new spells per day (and
spells known, if applicable) as if he had also gained a level in a
spellcasting class to which he belonged before adding the prestige class
level. If the character had more than one spellcasting class before
becoming a faith scion, the player must decide to which class to add each
faith scion level for the purpose of determining spells per day and spells
known.
    </p>
    <p><b>Special Abilities:</b>
Faith scion levels stack with other class levels for determining the
effectiveness of the following abilities: <a style={{
        "color": "#579eb6"
      }} href="druid.html#druid-animal-companion">animal companions</a>, <a style={{
        "color": "#579eb6"
      }} href="paladin.html#paladin-lay-on-hands">lay on hands</a>,
      <a style={{
        "color": "#579eb6"
      }} href="paladin.html#paladin-special-mount">special mount</a>, <a style={{
        "color": "#579eb6"
      }} href="cleric.html#cleric-turn-or-rebuke-undead">turn or rebuke undead</a>, <a style={{
        "color": "#579eb6"
      }} href="druid.html#druid-wild-shape">wild shape</a> (including additional
daily uses or increased range of sizes or creature types), and <a style={{
        "color": "#579eb6"
      }} href="druid.html#druid-wild-empathy">wild
empathy</a>. For instance, a 5th-level druid/6th-level faith scion could
assume wild shape four times per day (including Tiny creatures), and would
add 11 (plus Cha modifier) to his wild empathy checks. His animal
companion would be treated as that of an 11th-level druid.
    </p>
    <h6><a id="hammer-of-the-dwarffather"></a>
Hammer of the Dwarffather
    </h6>
The <i>Hammer of the Dwarffather</i> is said to have been crafted by the
dwarven gods and symbolizes the glory of the dwarven race.
    <p><b>Requirements:</b>
Any character can wield the <i>Hammer of the Dwarffather</i> as a +2
adamantine warhammer and also gain the benefit of the Craft bonus and lore
of souls special ability described below. A faith scion who wields the
      <i>Hammer of the Dwarffather</i> gains additional special abilities if
the character fulfills the following criteria.
    </p>
    <p><i>Race:</i> Dwarf.
    </p>
    <p><i>Alignment:</i>
Lawful good.
    </p>
    <p><i>Base Attack Bonus:</i>
+7.
    </p>
    <p><i>Skills:</i>
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#craft">Craft</a> (armorsmithing) or Craft (weaponsmithing) 10 ranks.
    </p>
    <p><i>Feats:</i>
      <a style={{
        "color": "#579eb6"
      }} href="featsAll.html#armor-proficiency-heavy">Armor Proficiency (heavy)</a>, <a style={{
        "color": "#579eb6"
      }} href="featsAll.html#great-fortitude">Great Fortitude</a>, <a style={{
        "color": "#579eb6"
      }} href="featsAll.html#power-attack">Power Attack</a>, <a style={{
        "color": "#579eb6"
      }} href="featsAll.html#weapon-focus">Weapon Focus</a>
(warhammer).
    </p>
    <p><i>Spells:</i>
Ability to cast <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#protection-from-evil">protection from evil</a> as a divine spell.
    </p>
    <p><b>Restrictions:</b>
Should the wielder of the <i>Hammer of the Dwarffather</i> become an
alignment other than lawful
good, or attack a lawful good, lawful neutral, or neutral good-aligned
dwarf, he loses all special abilities granted by the faith scion prestige
class. To regain these abilities, the scion must receive an <a style={{
        "color": "#579eb6"
      }} href="spellsAtoB.html#atonement">atonement</a>
spell from a dwarf cleric.
    </p>
    <p><b>Maintenance:</b>
A wielder of the <i>Hammer of the Dwarffather</i> must strike the hammer
against an anvil at least once every seven days. Failure to perform this
task reduces the hammer�s enhancement bonus by 1. Multiple
failures are not cumulative (that is, if the hammer goes two weeks without
striking an anvil, the reduction does not increase to 2).
    </p>
    <p><b>Attributes:</b>
The <i>Hammer of the Dwarffather</i> has the following attributes.
    </p>
    <p><i>Hardness/Hit Points:</i>
30/76.
    </p>
    <p><i>Caster Level:</i>
15th.
    </p>
    <p><i>Value:</i>
To any character other than a faith scion who meets the above
requirements, the <i>Hammer of the Dwarffather</i> appears to be worth
as much as a Medium +2 adamantine warhammer that grants the Craft bonus
and lore of all souls special ability (market price 21,812 gp).
    </p>
    <b><a id="table-abilities-granted-by-the-hammer-of-the-dwarffather"></a>Table: Abilities Granted by the Hammer of the Dwarffather</b>
    <table cellSpacing="0" style={{
      "width": "50%"
    }}>
      <tr>
        <th>
Faith Scion
Level
        </th>
        <th align="left">
Ability Gained
        </th>
      </tr>
      <tr className="odd-row">
        <td align="center">
�
        </td>
        <td>
Craft bonus
        </td>
      </tr>
      <tr>
        <td align="center">
�
        </td>
        <td>
Lore of souls
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
1st
        </td>
        <td>
Smite evil 1/day
        </td>
      </tr>
      <tr>
        <td align="center">
2nd
        </td>
        <td>
Fortitude of souls +2
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
3rd
        </td>
        <td>
Throw and return
        </td>
      </tr>
      <tr>
        <td align="center">
4th
        </td>
        <td>
Enhancement bonus +3
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
5th
        </td>
        <td>
Smite evil 2/day
        </td>
      </tr>
      <tr>
        <td align="center">
6th
        </td>
        <td>
Fortitude of souls +4
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
7th
        </td>
        <td>
Enhancement bonus +4
        </td>
      </tr>
      <tr>
        <td align="center">
8th
        </td>
        <td>
Fortitude of souls +6
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
9th
        </td>
        <td>
Smite evil 3/day
        </td>
      </tr>
      <tr>
        <td align="center">
10th
        </td>
        <td>
Enhancement bonus +5
        </td>
      </tr>
    </table>
    <p><b>Special Abilities:</b>
The <i>Hammer of the Dwarffather</i> provides a number of special
abilities to its wielder, depending on the character�s class level in the
faith scion prestige class (see the table below).
    </p>
    <p><i>Initial Abilities:</i>
When first acquired, the <i>Hammer of the Dwarffather</i> functions as a
+2 adamantine warhammer. Anyone who wields it, whether a faith scion or
not, also benefits from the Craft bonus and lore of souls ability (see
below).
    </p>
    <p><i>Craft Bonus (Ex):</i>
The owner of the <i>Hammer of the Dwarffather</i> gains a +5 insight bonus on all <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#craft">Craft</a> checks
related to stone or metal.
    </p>
    <p>
This bonus increases by an additional +1 for every faith scion class level
gained, including 1st level.
    </p>
    <p><i>Lore of Souls (Su):</i>
Three times per day, the owner of the <i>Hammer of the Dwarffather</i>
can use a full-round action to consult the knowledge of his dwarven
ancestors. This gives the character a bonus on any one <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#knowledge">Knowledge</a> check
(made as part of the action) equal to his faith scion level (if any) + his
Wisdom bonus (if any). (At the GM�s discretion, if the Knowledge check is
directly related to dwarvenkind, the bonus granted by the character�s
faith scion level is doubled.) The check is treated as a trained skill check, even if the
character has no ranks in that Knowledge skill.
    </p>
    <p><i>Smite Evil (Su):</i>
Once per day, a faith scion wielding the <i>Hammer of the
Dwarffather</i> may smite evil with an attack. The attack may be a melee
attack or a ranged attack, but must be delivered by the <i>Hammer of the
Dwarffather</i>. The wielder adds 4 to his attack roll and deals 2
extra points of damage per faith scion level. If the wielder accidentally
smites a creature that is not evil, the smite has no effect, but the
ability is still used up for that day.
    </p>
    <p>
A wielder may use this ability twice per day at 5th level and three times
per day at 9th level.
    </p>
    <p><b>Fortitude of Souls (Su):</b>
A faith scion of 2nd level or higher wielding the <i>Hammer of the
Dwarffather</i> derives endurance from the support of his ancestors,
gaining a +2 enhancement
bonus to Constitution. At 6th level, the bonus increases to +4, and at
8th level to +6.
    </p>
    <p><i>Throw and Return (Su):</i>
Three times per day, a faith scion of 3rd level or higher wielding the
      <i>Hammer of the Dwarffather</i> can treat the weapon as a throwing
      <a style={{
        "color": "#579eb6"
      }} href="magicItemsAW.htm#returning">returning</a> weapon.
Activating this ability is a free action, and it lasts for 1 round.
    </p>
    <h5><a id="spell-scion"></a>
SPELL SCION
    </h5>
Sometimes a magic weapon is not so much a weapon as a magic device in the
shape of a weapon. The spell scion is the ideal wielder for a legendary
weapon of this sort. Spellblades and similar items are weapons of
significant arcane power, and their wielders often have great magical
destinies. Such a character might wield a fey sword, or a demon blade, or
a magic staff that is actually an important symbol of his or her arcane
order.
    <p>
Wielding an arcane legendary weapon is a great responsibility because the
weapon contains so much magical power. Arcane spellcasters who recognize a
particular weapon usually afford the wielder more respect � or perhaps
fear � than they might another of their ilk who was not so equipped.
    </p>
    <p><b>Hit Die:</b>
d6.
    </p>
    <h6>
Requirements
    </h6>
To qualify to become a spell scion, a character must fulfill the criteria
given in her particular weapon�s entry. (For an example, see
    <a style={{
      "color": "#579eb6"
    }} href="kaiein-the-blazing-staff">Kaiein</a>, below.)
    <h4>
Class Skills
    </h4>
The spell scion�s class skills (and the key ability for each skill) are
    <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#concentration">Concentration</a> (Con), <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#craft">Craft</a> (Int), <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#knowledge">Knowledge</a> (all skills, taken
individually) (Int), <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#profession">Profession</a> (Wis), and <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#spellcraft">Spellcraft</a> (Int).
    <p><b>Skill Points at Each Level:</b>
2 + Int modifier.
    </p>
    <b><a id="table-the-spell-scion"></a>Table: The Spell Scion</b>
    <table cellSpacing="0" style={{
      "width": "70%"
    }}>
      <tr>
        <th>
Level
        </th>
        <th>
Base
Attack
Bonus
        </th>
        <th>
Fort
Save
        </th>
        <th>
Ref
Save
        </th>
        <th>
Will
Save
        </th>
        <th align="left">
Spells per Day/Spells Known
        </th>
      </tr>
      <tr className="odd-row">
        <td align="center">
1st
        </td>
        <td align="center">
+0
        </td>
        <td align="center">
+0
        </td>
        <td align="center">
+0
        </td>
        <td align="center">
+2
        </td>
        <td>
+1 level of existing arcane spellcasting class
        </td>
      </tr>
      <tr>
        <td align="center">
2nd
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+0
        </td>
        <td align="center">
+0
        </td>
        <td align="center">
+3
        </td>
        <td>
+1 level of existing arcane spellcasting class
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
3rd
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+3
        </td>
        <td>
+1 level of existing arcane spellcasting class
        </td>
      </tr>
      <tr>
        <td align="center">
4th
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+4
        </td>
        <td>
+1 level of existing arcane spellcasting class
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
5th
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+4
        </td>
        <td>
+1 level of existing arcane spellcasting class
        </td>
      </tr>
      <tr>
        <td align="center">
6th
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+5
        </td>
        <td>
+1 level of existing arcane spellcasting class
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
7th
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+5
        </td>
        <td>
+1 level of existing arcane spellcasting class
        </td>
      </tr>
      <tr>
        <td align="center">
8th
        </td>
        <td align="center">
+4
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+6
        </td>
        <td>
+1 level of existing arcane spellcasting class
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
9th
        </td>
        <td align="center">
+4
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+6
        </td>
        <td>
+1 level of existing arcane spellcasting class
        </td>
      </tr>
      <tr>
        <td align="center">
10th
        </td>
        <td align="center">
+5
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+7
        </td>
        <td>
+1 level of existing arcane spellcasting class
        </td>
      </tr>
    </table>
    <h6>
Class Features
    </h6>
The following are class features of the spell scion prestige class. A
legendary weapon associated with the class provides a unique set of
additional special abilities, which are detailed in the weapon�s
description. (For an example, see <i>Kaiein</i>, below.) These abilities
accrue in addition to those already noted on <a style={{
      "color": "#579eb6"
    }} href="#table-the-spell-scion">Table: The Spell Scion</a>.
However, because those abilities are functions of the weapon rather than
the wielder, they are lost if the legendary weapon that granted them is
lost. The features mentioned on Table: The Spell Scion are retained even
if the weapon is lost.
    <p><b>Spells per Day/Spells Known:</b>
At each spell scion level, the character gains new spells per day (and
spells known, if applicable) as if she had also gained a level in a
spellcasting class to which she belonged before adding the prestige class
level. She does not, however, gain any other benefit a character of that
class would have gained (metamagic or item creation feats, and so on). If
the character had more than one spellcasting class before becoming a spell
scion, the player must decide to which class to add each spell scion level
for the purpose of determining spells per day and spells known.
    </p>
    <h6><a id="kaiein-the-blazing-staff"></a>
Kaiein, the Blazing Staff
    </h6>
Created by a wizard from pure elemental fire, <i>Kaiein</i> is a conduit
between the wielder and the <a style={{
      "color": "#579eb6"
    }} href="planes.html#fire">Elemental Plane of Fire</a>. The staff is made of
polished obsidian and is wreathed at all times in wispy blue-white flame.
This flame deals no damage to the wielder.
    <p><b>Requirements:</b>
Any character can wield <i>Kaiein</i> as a +1 <a style={{
        "color": "#579eb6"
      }} href="magicItemsAW.html#flaming">flaming</a>/+1 flaming
quarterstaff and also gain the benefit of the resistance to fire special
ability described below. A spell scion who wields <i>Kaiein</i> gains
additional special abilities if the character fulfills the following
criteria.
    </p>
    <p><i>Alignment:</i>
Any nonlawful.
    </p>
    <p><i>Skills:</i>
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#knowledge">Knowledge</a> (arcana) 5 ranks, <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#knowledge">Knowledge</a> (the planes) 5 ranks, <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#spellcraft">Spellcraft</a> 10
ranks.
    </p>
    <p><i>Feats:</i>
      <a style={{
        "color": "#579eb6"
      }} href="featsAll.html#empower-spell">Empower Spell</a>.
    </p>
    <p><i>Spells:</i>
Ability to cast 4th-level arcane spells, including at least three spells
with the fire descriptor.
    </p>
    <p><i>Special:</i>
Must have been reduced to -1 or less hit points on at least one occasion
from a hit that dealt fire damage.
    </p>
    <p><b>Restrictions:</b>
If <i>Kaiein</i> is grasped by a creature with <a style={{
        "color": "#579eb6"
      }} href="monsterTypes.html#vulnerability-to-energy">vulnerability to cold</a>,
the weapon�s flame goes out temporarily. This renders all the staff�s
special abilities (except for its enhancement bonus) inactive. The
creature grasping the staff takes 1 point of fire damage in every round
when the weapon remains grasped.
    </p>
    <p><b>Maintenance:</b>
      <i>Kaiein</i> must be kept in a warm environment to remain at full
power. If the staff spends at least 1 hour in an area where the
temperature is below 50�F, its flame goes out temporarily. This renders
all the staff�s special abilities (except for its enhancement bonus) inactive.
This loss of special abilities can be postponed by dealing at least 5
points of fire damage to <i>Kaiein</i> every hour. (Damage dealt to the
staff "counts" for this purpose even if the staff actually takes no
damage, such as because of its hardness.)
    </p>
    <p><b>Attributes:</b>
      <i>Kaiein</i> has the following attributes.
    </p>
    <p><i>Hardness/Hit Points:</i>
14/45.
    </p>
    <p><i>Caster Level:</i>
15th.
    </p>
    <p><i>Value:</i>
To any character other than a spell scion who meets the above
requirements, <i>Kaiein</i> appears to be worth as much as a Medium +1
      <a style={{
        "color": "#579eb6"
      }} href="magicItemsAW.html#flaming">flaming</a>/+1 flaming quarterstaff that grants the resistance to fire ability
(market price 24,600 gp).
    </p>
    <b><a id="table-abilities-granted-by-kaiein"></a>Table: Abilities Granted by Kaiein</b>
    <table cellSpacing="0" style={{
      "width": "50%"
    }}>
      <tr>
        <th>
Spell Scion
Level
        </th>
        <th align="left">
Ability Gained
        </th>
      </tr>
      <tr className="odd-row">
        <td align="center">
�
        </td>
        <td>
Resistance to fire 5
        </td>
      </tr>
      <tr>
        <td align="center">
1st
        </td>
        <td>
Resistance to fire 10, empowered spells
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
2nd
        </td>
        <td>
Recall spell 1/day
        </td>
      </tr>
      <tr>
        <td align="center">
3rd
        </td>
        <td>
Fire shield
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
4th
        </td>
        <td>
Resistance to fire 20
        </td>
      </tr>
      <tr>
        <td align="center">
5th
        </td>
        <td>
Enhancement bonus
+2/+2, recall spell 2/day
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
6th
        </td>
        <td>
Plane shift
        </td>
      </tr>
      <tr>
        <td align="center">
7th
        </td>
        <td>
Resistance to fire 30
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
8th
        </td>
        <td>
Recall spell 3/day
        </td>
      </tr>
      <tr>
        <td align="center">
9th
        </td>
        <td>
Enhancement bonus
+3/+3
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
10th
        </td>
        <td>
Immunity to fire
        </td>
      </tr>
    </table>
    <p><b>Special Abilities:</b>
      <i>Kaiein</i> provides the following special abilities to its wielder,
depending on the character�s class level in the spell scion prestige class
(see the table below).
    </p>
    <p><i>Initial Abilities:</i>
When first acquired, <i>Kaiein</i> functions as a +1 <a style={{
        "color": "#579eb6"
      }} href="magicItemsAW.html#flaming">flaming</a>/ +1 flaming
quarterstaff. Anyone who wields it, whether a spell scion or not, also
benefits from the resistance to fire ability (see below).
    </p>
    <p><i>Resistance to Fire (Ex):</i>
The wielder of the staff gains <a style={{
        "color": "#579eb6"
      }} href="monsterTypes.html#resistance-to-energy">resistance to fire</a> 5, even if she has no
levels in the spell scion class.
    </p>
    <p>
A spell scion who wields the staff gains resistance to fire 10. At 4th
level, the resistance improves to 20, and at 7th level to 30. This
resistance does not stack with any other resistance to fire the wielder
may have.
    </p>
    <p><i>Empowered Spells (Su):</i>
Three times per day, a spell scion wielding <i>Kaiein</i> can empower
(as the <a style={{
        "color": "#579eb6"
      }} href="featsAll.html#empower-spell">Empower Spell</a> feat) any arcane spell she casts that has the fire
descriptor. She may apply this effect to any spell whose level is equal to
or less than her spell scion level, and may even apply it to spell-like
abilities cast by the staff itself. Activating this power is a free action
and has no effect on the spell�s level or its casting time.
    </p>
    <p><i>Recall Spell (Su):</i>
As a free action, a spell scion of 2nd level or higher can recall any
arcane spell she has just cast, as long as that spell has the fire
descriptor, up to a maximum spell level equal to her spell scion level.
She either restores that spell to her list of prepared spells (if she
prepares spells) or regains that spell slot (if she does not prepare
spells). She may only recall a spell cast in the same round that she uses
this ability.
    </p>
    <p>
This ability can be used once per day at 2nd level, twice per day at 5th
level, and three times per day at 8th level.
    </p>
    <p><i>Fire Shield (Sp):</i>
Once per day, when wielded by a spell scion of 3rd level or higher,
      <i>Kaiein</i> can wreathe the wielder in a warm <a style={{
        "color": "#579eb6"
      }} href="spellsFtoG.html#fire-shield">fire shield</a> of blue
flames.
    </p>
    <p><i>Enhancement Bonus (Ex):</i>
When <i>Kaiein</i> is wielded by a spell scion of 5th level or higher,
the staff�s enhancement
bonus improves from +1/+1 to +2/+2. The bonus increases to +3/+3 when
the wielder attains 9th level as a spell scion.
    </p>
    <p><i>Plane Shift (Sp):</i>
Once per day, <i>Kaiein</i> can transport a spell scion of 6th level or
higher to the <a style={{
        "color": "#579eb6"
      }} href="planes.html#fire">Elemental Plane of Fire</a>, just as if it had cast <a style={{
        "color": "#579eb6"
      }} href="spellsPtoR.html#plane-shift">plane shift</a>.
Additional willing characters can accompany the scion, as described in the
plane shift spell. The effect is more accurate than normal for plane
shift, delivering the scion 5 to 500 yards (not miles) from her intended
destination. The spell scion must have her own method of return, since
      <i>Kaiein</i> provides no return trip.
    </p>
    <p><i>Immunity to Fire (Ex):</i>
At 10th level, a spell scion wielding <i>Kaiein</i> gains immunity to
fire.
    </p>
    <h5><a id="swift-scion"></a>
SWIFT SCION
    </h5>
Some legendary weapons are meant for the hands of the quick and the
silent. A swift scion might inherit her weapon or acquire it "by
accident", but the weapon is no less part of her destiny than is the
legendary weapon of any other scion. Characters of any class can become
swift scions, but rogues, bards, rangers, and monks benefit most from the
special abilities of the legendary weapons associated with this class.
    <p>
Swift scions wield their weapons for a wide variety of purposes. A rogue
may use it to supplement her income, while a monk might merely seek to
master its use. A bard could draw upon the power of her weapon�s rich
history to enhance her music, while a ranger might use it as part of the
hunt. Of all legendary weapons, the ones associated with the swift scion
class are the most focused in their application. Each tends to favor
rogues, monks, rangers, or bards specifically, rather than a broad range
of classes as the other legendary weapons do.
    </p>
    <p><b>Hit Die:</b>
d6.
    </p>
    <h6>
Requirements
    </h6>
    <p>
To qualify to become a swift scion, a character must fulfill the criteria
given in her particular weapon�s entry. (For an example, see
      <a style={{
        "color": "#579eb6"
      }} href="nightphantom">>Nightphantom</a>, below.)
    </p>
    <h4>
Class Skills
    </h4>
    <p>
The swift scion�s class skills (and the key ability for each skill) are
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#balance">Balance</a> (Dex), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#bluff">Bluff</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#climb">Climb</a> (Str), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#craft">Craft</a> (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#diplomacy">Diplomacy</a> (Cha),
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#escape-artist">Escape Artist</a> (Dex), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#hide">Hide</a> (Dex), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#jump">Jump</a> (Str), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#listen">Listen</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#move-silently">Move Silently</a>
(Dex), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#perform">Perform</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#profession">Profession</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#search">Search</a> (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#sense-motive">Sense Motive</a> (Wis),
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#spot">Spot</a> (Wis), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#swim">Swim</a> (Str), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#tumble">Tumble</a> (Dex), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#use-rope">Use Rope</a> (Dex).
    </p>
    <p><b>Skill Points at Each Level:</b>
6 + Int modifier.
    </p>
    <b><a id="table-the-swift-scion"></a>Table: The Swift Scion</b>
    <table cellSpacing="0" style={{
      "width": "70%"
    }}>
      <tr>
        <th>
Level
        </th>
        <th>
Base
Attack
Bonus
        </th>
        <th>
Fort
Save
        </th>
        <th>
Ref
Save
        </th>
        <th>
Will
Save
        </th>
        <th>
Special
        </th>
      </tr>
      <tr className="odd-row">
        <td align="center">
1st
        </td>
        <td align="center">
+0
        </td>
        <td align="center">
+0
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+0
        </td>
        <td>
Bonus class skill
        </td>
      </tr>
      <tr>
        <td align="center">
2nd
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+0
        </td>
        <td>
Bonus special ability
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
3rd
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+1
        </td>
        <td>
�
        </td>
      </tr>
      <tr>
        <td align="center">
4th
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+4
        </td>
        <td align="center">
+1
        </td>
        <td>
�
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
5th
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+1
        </td>
        <td align="center">
+4
        </td>
        <td align="center">
+1
        </td>
        <td>
Bonus class skill
        </td>
      </tr>
      <tr>
        <td align="center">
6th
        </td>
        <td align="center">
+4
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+5
        </td>
        <td align="center">
+2
        </td>
        <td>
Bonus special ability
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
7th
        </td>
        <td align="center">
+5
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+5
        </td>
        <td align="center">
+2
        </td>
        <td>
�
        </td>
      </tr>
      <tr>
        <td align="center">
8th
        </td>
        <td align="center">
+6
        </td>
        <td align="center">
+2
        </td>
        <td align="center">
+6
        </td>
        <td align="center">
+2
        </td>
        <td>
�
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
9th
        </td>
        <td align="center">
+6
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+6
        </td>
        <td align="center">
+3
        </td>
        <td>
�
        </td>
      </tr>
      <tr>
        <td align="center">
10th
        </td>
        <td align="center">
+7
        </td>
        <td align="center">
+3
        </td>
        <td align="center">
+7
        </td>
        <td align="center">
+3
        </td>
        <td>
Bonus special ability
        </td>
      </tr>
    </table>
    <h6>
Class Features
    </h6>
    <p>
The following are class features of the swift scion prestige class. A
legendary weapon associated with the class provides a unique set of
additional special abilities, which are detailed in the weapon�s
description. (For an example, see <em>Nightphantom</em>, below.) These
abilities accrue in addition to those already noted on <a style={{
        "color": "#579eb6"
      }} href="#table-the-swift-scion">Table: The Swift
Scion</a>. However, because those abilities are functions of the weapon rather
than the wielder, they are lost if the legendary weapon that granted them
is lost. The features mentioned on Table: The Swift Scion are retained
even if the weapon is lost.
    </p>
    <p><b>Bonus Class Skill:</b>
At 1st level, 5th level, and 9th level, a swift scion may choose an
additional class skill from the following list: <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#appraise">Appraise</a> (Int),
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#concentration">Concentration</a> (Con), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#decipher-script">Decipher Script</a> (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#disable-device">Disable Device</a> (Dex), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#disguise">Disguise</a>
(Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#gather-information">Gather Information</a> (Cha), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#knowledge">Knowledge</a> (all skills, taken
individually) (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#sleight-of-hand">Sleight of Hand</a> (Dex), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#speak-language">Speak Language</a> (Int),
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#spellcraft">Spellcraft</a> (Int), <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#survival">Survival</a> (Wis), and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#use-magic-device">Use Magic Device</a> (Cha). Once
designated, that skill is considered a class skill for the swift scion
prestige class.
    </p>
    <p><b>Bonus Special Ability:</b>
At 2nd level, 6th level, and 10th level, a swift scion may choose an
additional special ability from the following list. She may select the
same ability more than once if desired, and the effects stack where
appropriate.
    </p>
    <p><i>Arcane Spells per Day/Spells Known:</i>
A swift scion may gain a number of new spells per day and spells known (if
applicable) as if she had also gained a level in a spellcasting class to
which she belonged before adding the prestige class. She does not,
however, gain any other benefit a character of that class would have
gained (metamagic or item creation feats, and so on). If the character had
more than one spellcasting class before becoming a swift scion, the player
must decide to which class to add each swift scion level for the purpose
of determining spells per day and spells known.
    </p>
    <p><i>Bardic Music:</i>
A swift scion may choose to gain an extra two daily uses of the bardic music ability, provided
that she already has the ability. She doesn�t gain any new bardic music
abilities, nor do her existing bardic music abilities improve in
effectiveness.
    </p>
    <p><i>Fast Movement (Ex):</i>
If a swift scion has an enhancement bonus to her speed
from a class feature, she may choose to increase this enhancement bonus by
10 feet. This enhancement bonus follows the same rules as her existing
enhancement bonus to speed (for instance, a monk who selects this feature
only benefits from the enhancement bonus when wearing no armor).
    </p>
    <p><i>Improved Bardic Knowledge:</i>
A swift scion can choose to gain a +4 bonus on bardic knowledge checks.
(If the character doesn�t have the <a style={{
        "color": "#579eb6"
      }} href="bard.html#bardic-knowledge">bardic knowledge</a> class feature, this
benefit has no effect.)
    </p>
    <p><i>Improved Evasion (Ex):</i>
A swift scion may choose to gain the <a style={{
        "color": "#579eb6"
      }} href="monk.html#monk-improved-evasion">improved evasion</a>
ability, provided that she already has the <a style={{
        "color": "#579eb6"
      }} href="monk.html#monk-evasion">evasion</a> ability.
    </p>
    <p><i>Improved Favored Enemy:</i>
If a swift scion has at least one <a style={{
        "color": "#579eb6"
      }} href="ranger.html#ranger-favored-enemy">favored enemy</a>, the bonus gained
on damage rolls and certain skill checks against any one
favored enemy improves by +2. The character doesn�t gain any additional
favored enemies by selecting this ability.
    </p>
    <p><i>Improved Unarmed Damage:</i>
If a swift scion has the <a style={{
        "color": "#579eb6"
      }} href="featsAll.html#improved-unarmed-strike">Improved Unarmed Strike</a> feat, she may choose to
increase the damage dealt by her unarmed strikes. Treat this
as if the character�s monk level had increased by four. For instance, a
6th-level monk/2nd-level swift scion who selects this ability would
increase her unarmed strike damage from 1d8 to 1d10. (If the character has
no monk levels, she gains unarmed damage as a 4th-level monk.)
    </p>
    <p><i>Improved Uncanny Dodge (Ex):</i>
If a swift scion has the <a style={{
        "color": "#579eb6"
      }} href="rogue.html#rogue-uncanny-dodge">uncanny dodge</a> class feature, she may select
      <a style={{
        "color": "#579eb6"
      }} href="rogue.html#rogue-improved-uncanny-dodge">improved uncanny dodge</a>. Her swift scion levels stack with her levels in
other classes that grant uncanny dodge to determine the
minimum rogue level required to flank the character.
    </p>
    <p><i>Sneak Attack:</i>
If a swift scion has the <a style={{
        "color": "#579eb6"
      }} href="rogue.html#rogue-sneak-attack">sneak attack</a> ability, she may choose to increase
the extra damage it deals by 2d6 points.
    </p>
    <p><i>Swift Tracker (Ex):</i>
If a swift scion has the <a style={{
        "color": "#579eb6"
      }} href="featsAll.html#track">Track</a> feat, she may select this special ability.
A swift scion with this ability can move at her normal speed while
following tracks without taking the normal -5 penalty. She takes only a
-10 penalty (instead of the normal -20) when moving at up to twice normal
speed while tracking.
    </p>
    <p><i>Trap Sense:</i>
If a swift scion has the <a style={{
        "color": "#579eb6"
      }} href="barbarian.html#barbarian-trap-sense">trap sense</a> ability, she can take this special
ability to increase her bonus on Reflex saves and to AC
against traps by 1.
    </p>
    <h6><a id="nightphantom"></a>
Nightphantom
    </h6>
A cabal of halfling clerics created the bow called <i>Nightphantom</i>
long ago. Since then, it has been passed down along a line of elite
rangers and rogues, but was recently lost when its wielder was slain while
away from her village.
    <p><b>Requirements:</b>
Any character can wield <i>Nightphantom</i> as a +2 composite shortbow
(+2 Str bonus) and also gain the benefit of the stealth special ability
described below. A swift scion who wields <i>Nightphantom</i> gains
additional special abilities if the character fulfills the following
criteria.
    </p>
    <p><i>Base Attack Bonus:</i>
+6.
    </p>
    <p><i>Skills:</i>
      <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#hide">Hide</a> 10 ranks, <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#move-silently">Move Silently</a> 10 ranks.
    </p>
    <p><i>Feats:</i>
      <a style={{
        "color": "#579eb6"
      }} href="featsAll.html#point-blank-shot">Point Blank Shot</a>, <a style={{
        "color": "#579eb6"
      }} href="featsAll.html#far-shot">Far Shot</a>, <a style={{
        "color": "#579eb6"
      }} href="featsAll.html#stealthy">Stealthy</a>.
    </p>
    <p><i>Special:</i>
The wielder must be of Small size.
    </p>
    <p><b>Restrictions:</b>
      <i>Nightphantom</i> does not grant its stealth ability to a wielder who
is not of Small size, though its enhancement bonus is still
effective.
    </p>
    <p><b>Attributes:</b>
      <i>Nightphantom</i> has the following attributes.
    </p>
    <p><i>Hardness/Hit Points:</i>
15/55.
    </p>
    <p><i>Caster Level:</i>
15th.
    </p>
    <p><i>Value:</i>
To any character other than a swift scion who meets the above
requirements, <i>Nightphantom</i> appears to be worth as much as a Small
+2 composite shortbow (+2 Str bonus) that grants the stealth ability
(market price 10,525 gp).
    </p>
    <b><a id="table-abilities-granted-by-nightphantom"></a>Table: Abilities Granted by Nightphantom</b>
    <table cellSpacing="0" style={{
      "width": "50%"
    }}>
      <tr>
        <th>
Swift Scion
Level
        </th>
        <th>
Ability Gained
        </th>
      </tr>
      <tr className="odd-row">
        <td>
�
        </td>
        <td>
Stealth
        </td>
      </tr>
      <tr>
        <td>
1st
        </td>
        <td>
Invisibility
        </td>
      </tr>
      <tr className="odd-row">
        <td>
2nd
        </td>
        <td>
Ghost touch
        </td>
      </tr>
      <tr>
        <td>
3rd
        </td>
        <td>
Ephemeral presence (trackless)
        </td>
      </tr>
      <tr className="odd-row">
        <td>
4th
        </td>
        <td>
Enhancement bonus +3
        </td>
      </tr>
      <tr>
        <td>
5th
        </td>
        <td>
Dreamstrike arrows
        </td>
      </tr>
      <tr className="odd-row">
        <td>
6th
        </td>
        <td>
Greater invisibility
        </td>
      </tr>
      <tr>
        <td>
7th
        </td>
        <td>
Enhancement bonus +4
        </td>
      </tr>
      <tr className="odd-row">
        <td>
8th
        </td>
        <td>
Ephemeral presence (scentless)
        </td>
      </tr>
      <tr>
        <td>
9th
        </td>
        <td>
Etherealness
        </td>
      </tr>
      <tr className="odd-row">
        <td>
10th
        </td>
        <td>
Enhancement bonus +5
        </td>
      </tr>
    </table>
    <p><b>Special Abilities:</b>
      <i>Nightphantom</i> provides a number of special abilities to its
wielder, depending on the character�s class level in the swift scion
prestige class (see the table below).
    </p>
    <p><i>Initial Abilities:</i>
When it is first acquired, <i>Nightphantom</i> functions as a +2
composite shortbow (+2 Str bonus). Any Small character who wields it,
whether a swift scion or not, also benefits from the stealth ability (see
below).
    </p>
    <p><i>Stealth (Su):</i>
The wielder of <i>Nightphantom</i> may use her Wisdom bonus (if any) as
a bonus on her <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#hide">Hide</a> and <a style={{
        "color": "#579eb6"
      }} href="skillsAll.html#move-silently">Move Silently</a> checks.
    </p>
    <p><i>Invisibility (Sp):</i>
A swift scion wielding <i>Nightphantom</i> can become <a style={{
        "color": "#579eb6"
      }} href="spellsHtoL.html#invisibility">invisible</a> once per
day as a free action. The effect lasts for 1 round per class level.
    </p>
    <p>
For a swift scion of 6th level or higher, the invisibility granted by this
power is the equivalent of <a style={{
        "color": "#579eb6"
      }} href="spellsHtoL.html#greater-invisibility">greater invisibility</a>. The duration remains 1
round per class level.
    </p>
    <p><i>Ghost Touch (Su):</i>
When <i>Nightphantom</i> is wielded by a swift scion of 2nd level or
higher, any arrow fired from the bow can damage incorporeal creatures as
if it had the <a style={{
        "color": "#579eb6"
      }} href="magicItemsAW.html#ghost-touch-weapon">ghost touch</a> special ability.
    </p>
    <p><i>Ephemeral Presence (Su):</i>
A swift scion of 3rd level or higher bearing <i>Nightphantom</i> leaves
no trail and cannot be tracked.
    </p>
    <p>
At 8th level, a swift scion bearing the weapon no longer gives off a
scent. Creatures with the <a style={{
        "color": "#579eb6"
      }} href="monsterTypes.html#scent">scent</a> ability cannot detect her presence by
means of that ability, nor can she be tracked by scent.
    </p>
    <p><i>Enhancement Bonus (Ex):</i>
When <i>Nightphantom</i> is wielded by a swift scion of 4th level or
higher, the bow�s enhancement bonus improves from
+2 to +3. The bonus increases to +4 at 7th level and +5 at 10th level.
    </p>
    <p><i>Dreamstrike Arrows (Su):</i>
Three times per day (but no more than once per round), a swift scion of
5th level or higher can fire a special arrow from <i>Nightphantom</i>.
The arrow strikes as a touch attack (ignoring armor, shield, and natural armor bonuses to AC).
The arrow deals only half the normal damage, but the damage dealt is
      <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#ability-damaged">Wisdom damage</a>, not hit point damage. Bonus damage dice, if any, do not
apply to the damage dealt by a dreamstrike arrow.
    </p>
    <p><i>Etherealness (Su):</i>
At 9th level or higher, a swift scion bearing <i>Nightphantom</i> can
become ethereal for up to 10 rounds per day (which need not be spent all
at one time) Activating this power, or deactivating it to become material,
is a standard action.
    </p>
    <h3><a id="item-familiars"></a>ITEM FAMILIARS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/UnA_Gallery/79173.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a>
Like the <a style={{
      "color": "#579eb6"
    }} href="#legendary-weapons">legendary
weapons</a> rules, the item familiars variant system presents a method by
which a character, even a nonspellcaster, can find himself linked to a
particular magic item for a large part of his career. These items
gradually gain in power and sentience, and often fulfill small roles
similar to those of living familiars, but sometimes they become powerful
entities in their own right.
    <p>
To utilize these rules, the character must choose the following new feat.
    </p>
    <h5><a id="item-familiar"></a>
Item Familiar [General]
    </h5>
Choose a permanent magic item that you possess. You establish a link to
that magic item, and the item improves in capability as you gain levels.
    <p><b>Prerequisite:</b>
A character must be at least 3rd level to take this feat.
    </p>
    <p><b>Benefit:</b>
By establishing a link to a particular item, you enable that item to gain
power as you gain levels. The exact nature of the item and the powers are
described in the following text.
    </p>
    <p><b>Special:</b>
If you ever lose the chosen item (have it removed from your possession for
a continuous period of more than one day per level) or if the item is
destroyed, you automatically lose 200 XP per level as well as all benefits
derived from possessing the linked item (plus any resources you put into
the item). If you recover the item, you regain these XP. You may replace a
lost or destroyed item familiar after you have advanced one level, as if
you were gaining an item familiar for the first time.
    </p>
    <h5><a id="types-of-item-familiars"></a>
Types Of Item Familiars
    </h5>
An item familiar must be a permanent magic item. Typically, it tends to be
a magic weapon (such as a sword, axe, or bow), a rod (one that does not
depend on charges for its powers), or a ring with a permanent magical
power. The GM may allow for various wondrous items to be item familiars,
and in such a case can adapt the following rules fairly easily.
    <p>
In order to be an item familiar, a magic item must:
    </p>
    <ul>
      <li>Have a price of at least 2,000 gp.
      </li>
      <li>Be usable by the character (if it is a weapon, the character must be
proficient with the appropriate category of weapon).
      </li>
      <li>Have a permanent magical effect that the character can (and knows how
to) use.
      </li>
    </ul>
    <p>
Keep in mind that the item only needs to meet the basics of this criteria.
The magic item may have functions the character cannot currently use, and
once the item is linked to the character he can separate from it for short
periods of time without any harm.
    </p>
    <h6><a id="intelligent-items"></a>
Intelligent Items
    </h6>
This variant system does not depend on the item in question being intelligent, but any item
familiar created eventually becomes intelligent. Item familiars normally
become intelligent gradually, however, which lessens the complexity of the
standard rules for intelligent items.
    <h5><a id="bonding-to-an-item-familiar"></a>
BONDING TO AN ITEM FAMILIAR
    </h5>
When a character selects an item and chooses the <a style={{
      "color": "#579eb6"
    }} href="#item-familiar">Item Familiar</a> feat, the
character establishes a permanent, supernatural bond to the item familiar.
This bond can be suppressed by an antimagic field or similar effect, but
it cannot be dispelled.
    <p>
Once the character has become bonded to the magic item, the item may gain
additional powers or intelligence. The character can also begin investing
abilities into the item, using the item familiar to improve his own
capabilities.
    </p>
    <p>
Use <a style={{
        "color": "#579eb6"
      }} href="#table-item-familiar-abilities">Table: Item Familiar Abilities</a> to determine what, if any, abilities
the item familiar gains based on its owner�s character level.
    </p>
    <b><a id="table-item-familiar-abilities"></a>Table: Item Familiar Abilities</b>
    <table cellSpacing="0" style={{
      "width": "50%"
    }}>
      <tr>
        <th>
Character Level
        </th>
        <th align="left">
Ability
        </th>
      </tr>
      <tr className="odd-row">
        <td align="center">
1st
        </td>
        <td>
Invest life energy; invest skill ranks; invest spell slots
        </td>
      </tr>
      <tr>
        <td align="center">
7th
        </td>
        <td>
Sapience; senses; communication
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
10th
        </td>
        <td>
Special ability
        </td>
      </tr>
      <tr>
        <td align="center">
14th
        </td>
        <td>
Special ability
        </td>
      </tr>
      <tr className="odd-row">
        <td align="center">
18th
        </td>
        <td>
Special ability
        </td>
      </tr>
      <tr>
        <td align="center">
21st or higher
        </td>
        <td>
One additional special ability per three character levels above 20th
        </td>
      </tr>
    </table>
    <h6><a id="invest-life-energy"></a>
Invest Life Energy
    </h6>
A character of 6th level or lower may invest a portion of his life force
into his item familiar, receiving bonus XP in return. These XP are
actually part of the item, however, so if the item is lost or destroyed,
the character loses not only the bonus but a quantity of his existing XP
as well.
    <p>
When a character chooses to invest his life energy into his item familiar,
his current XP total and all future XP awards increase by 10%. However, if
the character loses the item, he loses all bonus XP gained, plus an
additional 200 XP per character level.
    </p>
    <p>
For example, Boredflak, a 6th-level character with 19,000 XP, chooses to
invest his item familiar, a ring, with some of his life energy. He adds
1,900 XP (10% of 19,000) to his XP total, so he now has 20,900 XP. If he
goes on an adventure and earns another 1,000 XP, he actually gains 1,100
XP (1,000 + 10% of 1,000), increasing his total to 22,000 XP, which makes
him a 7th-level character.
    </p>
    <p>
If he then loses the ring, he would lose the 2,000 XP gained from the
investiture (the 1,900 XP he received originally plus the bonus 100 XP he
earned later), plus an additional 1,400 XP (200 XP per level), for a total
loss of 3,400 XP. This loss would reduce his XP total to 18,600 and his
character level to 6th.
    </p>
    <h6><a id="invest-skill-ranks"></a>
Invest Skill Ranks
    </h6>
Whenever a character with an item familiar gains skill points, he may
choose to put some or all of those skill points into his item familiar. He
assigns the skill points normally, but notes that they now reside in the
item familiar. For every 3 ranks he assigns to the item familiar, he gains
a +1 bonus that he can apply to any single skill. This bonus can be
applied to a skill in which he already has maximum ranks. He can apply
multiple bonuses to the same skill, but he may not have more points of
bonus in a skill than he has ranks.
    <p>
If the character loses the item familiar, is separated from it for one day
per level (see the <a style={{
        "color": "#579eb6"
      }} href="#item-familiar">Item Familiar</a> feat
description), or if the item familiar is destroyed, these skill points and
the bonuses related to them are lost.
    </p>
    <p>
For example, Boredflak has just achieved 7th character level, and he takes
a level of wizard. Because of his high Intelligence score, he gains 7
skill points. He assigns 1 skill point to each of the following skills:
    </p>
    <ul>
      <li>Concentration
      </li>
      <li>Decipher Script*
      </li>
      <li>Knowledge (arcana)*
      </li>
      <li>Knowledge (dungeoneering)*
      </li>
      <li>Knowledge (nobility and royalty)*
      </li>
      <li>Knowledge (the planes)*
      </li>
      <li>Spellcraft*
      </li>
    </ul>
    <p>
He uses an asterisk to note that 1 rank for each of six skills resides in
his ring. Since that adds up to a total of 6 skill ranks in the ring, he
gains two +1 bonuses he can apply to any skill. He decides to assign both
bonuses (a total of +2) to his Concentration skill. Boredflak only has 1
rank in the cross-class skill Spot. If he had desired, he could have
applied a single +1 bonus to that skill, but not both.
    </p>
    <h6><a id="invest-spell-slots"></a>
Invest Spell Slots
    </h6>
Only spellcasters may choose to use this option. A character with an item
familiar may choose to invest a single spell slot in his familiar and gain
a bonus spell slot in return. The single spell slot must be of the highest
spell level he can cast, and the bonus spell slot is always two levels
lower than the slot invested in the item. As the caster gains (or loses)
levels, the spell slot invested in the item changes so that it is always
of the highest spell level he can cast, and the bonus spell slot also
changes accordingly, remaining two levels lower than that.
    <p>
If a spellcaster does not have a spell slot two levels lower than the
highest spell level he can cast (if he can cast only 0- and 1st-level
spells), he cannot use this option.
    </p>
    <p>
As with all other investiture options, if the item familiar is lost or
destroyed, so are both spell slots.
    </p>
    <p>
For example, as a 7th-level wizard, Boredflak can cast 4th-level spells.
He chooses to invest one 4th-level spell slot in his ring. The ring gains
an additional 2nd-level spell slot, which Boredflak can use as long as he
has the ring in his possession. When Boredflak attains 9th level, the
spell slot assigned to the ring automatically becomes a 5th-level spell
slot, and the bonus slot becomes a 3rd-level spell slot instead of a
2nd-level one.
    </p>
    <h6><a id="sapience"></a>
Sapience
    </h6>
If a character with an item familiar is at least 7th level, the item gains
rudimentary sapience. It gains Intelligence, Wisdom, and Charisma scores.
Two of these scores (player�s or GM�s choice) are 10 and one is 12. The
item familiar also gains an <a style={{
      "color": "#579eb6"
    }} href="magicItemsICA.html#item-ego">Ego score</a>. This last
score should not come into play very often � an item familiar is completely
loyal to its master, unless its master radically changes alignment or one or the other is
affected by some strange compulsion.
    <h6><a id="senses"></a>
Senses
    </h6>
If a character with an item familiar is at least 7th level, the item can
see and hear in a 60-foot radius as if it were a creature. It does not
normally make <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#spot">Spot</a> or <a style={{
      "color": "#579eb6"
    }} href="skillsAll.html#listen">Listen</a> checks separately from its master, but its
master gains the benefit of the <a style={{
      "color": "#579eb6"
    }} href="featsAll.html#alertness">Alertness</a> feat while wielding the item.
    <h6><a id="communication"></a>
Communication
    </h6>
If a character with an item familiar is at least 7th level, the item
begins to communicate with the master using basic emotions or feelings.
The item may try to tell the master of danger, for example, by putting
forth a feeling of fear. It can only communicate in this manner while
being worn or carried by its master.
    <h6><a id="speical-ability"></a>
Special Ability
    </h6>
When an item familiar�s master reaches 10th level, and at every four
levels thereafter, the master chooses a new special ability for the item
from the following list. Once an ability is chosen, it becomes a permanent
part of the item (unless otherwise specified). Some abilities have
prerequisites.
    <p><b><a id="armor-shield-or-weapon-special-ability"></a>Armor, Shield, or Weapon Special Ability:</b>
An item familiar empowered with this special ability gains an ability
equivalent to a +1 bonus (as found on <a style={{
        "color": "#579eb6"
      }} href="magicItemsAW.html#table-melee-weapon-special-abilities">Table:
Melee Weapon Special Abilities</a>, or <a style={{
        "color": "#579eb6"
      }} href="magicItemsAW.html#table-ranged-weapon-special-abilities">Table:
Ranged Weapon Special Abilities</a>), such as the light fortification,
bashing, or defending special ability. This ability contributes to the
overall enhancement
bonus of the item and its value but does not cost the master of the
familiar any gold pieces or time. This ability may be used in conjunction
with the normal rules for improving an existing magic item (see <a style={{
        "color": "#579eb6"
      }} href="#improving-an-item-familiar">Improving an Item Familiar</a>, and
      <a style={{
        "color": "#579eb6"
      }} href="magicItemsCreation.html#adding-new-abilities">Adding New
Abilities</a>). A character may select this special ability multiple
times, each time enabling his familiar to gain a different ability
equivalent to a +1 bonus.
    </p>
    <p><i>Prerequisite:</i>
The item familiar must be a type of magic armor, a magic shield, or a
magic weapon.
    </p>
    <p><b><a id="cantrips-orisons"></a>Cantrips/Orisons:</b>
An item familiar empowered with this special ability can cast 0-level
spells. The familiar may cast any spell invested in it as a standard
action (or longer, as defined by the spell�s casting time) as long as the
spell does not have an expensive material component or an XP component.
The item familiar need not provide any verbal or somatic components, and
it need not provide any material components that cost less than 1 gp. It
has access to all the 0-level spells from any single class spell list of
the master�s choice (taking into account any alignment restrictions against
casting spells of a certain class or alignment subtype). It can cast a
number of 0-level spells per day as if it were a sorcerer of the master�s
character level (though the master does not have to be a spellcaster). The
item familiar uses its own ability scores to determine spell save DCs but
can cast its 0-level spells only on its master�s order. The master may use
a free action on his turn to issue these orders, or he may give a number
of contingency orders (such as "If I fall unconscious, cast <a style={{
        "color": "#579eb6"
      }} href="spellsC.html#cure-minor-wounds">cure minor
wounds</a> on me") equal to one more than his Charisma modifier (minimum one).
    </p>
    <p><b><a id="greater-power"></a>Greater Power:</b>
An item familiar empowered with this special ability gains any single
greater power listed on the <a style={{
        "color": "#579eb6"
      }} href="magicItemsICA.html#table-intelligent-item-greater-powers">Intelligent
Item Greater Powers</a> table. The item uses this power as described, at
the master�s command. A character may select this special ability multiple
times, each time applying it to a different greater power.
    </p>
    <p><i>Prerequisite:</i>
An item must have at least one <a style={{
        "color": "#579eb6"
      }} href="#lesser-power">lesser power</a> for
every greater power it is given. The master must spend the amount of gold
pieces given in the Base Price Modifier column of the Intelligent Item
Greater Powers table to purchase the greater power. The process of
empowering an item in this way takes 24 hours.
    </p>
    <p><b><a id="greater-senses"></a>Greater Senses:</b>
An item familiar empowered with this special ability gains <a style={{
        "color": "#579eb6"
      }} href="monsterTypes.html#blindsense">blindsense</a> out
to 30 feet.
    </p>
    <p><i>Prerequisite:</i>
The item familiar must already have the <a style={{
        "color": "#579eb6"
      }} href="#improved-senses">improved
senses</a> special ability.
    </p>
    <p><b><a id="improved-senses"></a>Improved Senses:</b>
An item familiar empowered with this special ability gains darkvision out
to 60 feet.
    </p>
    <p><b><a id="increased-sapience"></a>Increased Sapience:</b>
An item familiar empowered with this special ability gains +4 to any
single ability score and +2 to its other two scores. The item can now
communicate telepathically in a recognizable language with the master out
to 120 feet and can speak audibly in Common. It can speak, read, and
understand one additional language per point of Intelligence bonus. A
character may select this special ability multiple times, each time
improving all three of the item�s ability scores and increasing the number
of languages it can speak, read, and understand.
    </p>
    <p><b><a id="lesser-power"></a>Lesser Power:</b>
An item familiar empowered with this special ability gains any single
lesser power listed on the <a style={{
        "color": "#579eb6"
      }} href="magicItemsICA.html#table-intelligent-item-lesser-powers">Intelligent
Item Lesser Powers</a> table. The item uses this power as described, at
the master�s command. A character may select this special ability multiple
times, each time applying it to a different lesser power.
    </p>
    <p><b>Prerequisite:</b>
The master must spend the amount of gold pieces given in the Base Price
Modifier column of the Intelligent Item Lesser Powers table to purchase
the lesser power. The process of empowering an item in this way takes 24
hours.
    </p>
    <p><b><a id="special-purpose-and-dedicated-power"></a>Special Purpose and Dedicated Power:</b>
An item familiar empowered with this special ability gains a special
purpose and a dedicated power chosen by its master (see the <a style={{
        "color": "#579eb6"
      }} href="magicItemsICA.html#table-intelligent-item-purpose">Intelligent
Item Purpose</a> and <a style={{
        "color": "#579eb6"
      }} href="magicItemsICA.html#table-special-purpose-item-dedicated-powers">Special
Purpose Item Dedicated Powers</a> tables).
    </p>
    <p>
An item familiar usually displays more flexibility in how it carries out
its special purpose than a standard intelligent item, especially if its
purpose conflicts with its master. However, if a master (especially one
who gave the item a special purpose in the first place) consistently acts
against the item�s special purpose, the item has even more leverage for
keeping the "master" in line. An item familiar can temporarily sever the
link between item and master, essentially shutting down access to any
abilities invested in the item plus all its normal magical abilities, as
if the item had been lost or destroyed. The item only reestablishes the
link if it is convinced the master is committed to helping it fulfill its
special purpose.
    </p>
    <p>
No item familiar may have more than one special purpose and one dedicated
power.
    </p>
    <p><i>Prerequisite:</i>
The master must spend the amount of gold pieces given in the Base Price
Modifier column of the Special Purpose Item Dedicated Powers table to
purchase the dedicated power. The process of empowering an item in this
way takes 24 hours.
    </p>
    <p><b><a id="spell-use"></a>Spell Use:</b>
An item familiar empowered with this ability may cast any spell invested
in it as a standard action (or longer, as defined by the spell�s duration)
as long as it does not have an expensive material component or an XP
component. The item familiar need not provide any verbal or somatic
components, and it need not provide any material components that cost less
than 1 gp. The item familiar must meet the ability score prerequisites for
the spell but casts the spell at the master�s level. The item familiar may
cast the spell only on the master�s order (as described in <a style={{
        "color": "#579eb6"
      }} href="#cantrips-orisons">Cantrips/
Orisons</a>, above). If an item familiar casts an invested spell, it is as if
the master cast it for purposes of spells per day and preparation.
    </p>
    <p><i>Prerequisite:</i>
The item must have an <a style={{
        "color": "#579eb6"
      }} href="#invest-spell-slots">invested spell slot</a>
of the appropriate spell level, and the master must have the ability to
cast 3rd-level spells.
    </p>
    <h5><a id="item-familiar-alignment"></a>
ITEM FAMILIAR ALIGNMENT
    </h5>
An item familiar gains its master�s alignment and, if the character
changes alignment, it generally changes alignment accordingly. However, if
this alignment change would be in direct conflict with the item familiar�s
special purpose (if any), the item does not change alignment, and it
immediately severs the link between itself and its master. The link can
only be reestablished when the master changes to a nonconflicting
alignment.
    <p>
If an item familiar changes to an alignment that would preclude it
using some of its powers not tied to a special purpose, the change in
alignment takes place, no severing of the link occurs, and the item cannot
use those powers until its alignment becomes compatible again. For
example, if a neutral good rod has the ability to cast druid cantrips and
the rod becomes lawful good, it loses that ability. If a holy avenger item
familiar becomes nonlawful, it loses all the abilities it had for being a
holy avenger but remains a +2 cold iron longsword and retains its other
item familiar abilities.
    </p>
    <h5><a id="improving-an-item-familiar"></a>
Improving An Item Familiar
    </h5>
An item familiar can be improved as other magic items can be. By spending
gold pieces (and time and experience points, assuming the character is the
one doing the work), a character can add new abilities to his item
familiar. If a character links himself to a +1 longsword, for example, it
only costs 6,000 gp (or 3,000 gp and 240 XP) to add another +1 of enhancement bonus or, perhaps, a
special ability that is equivalent to a +1 bonus (such as spell storing or
flaming). The character can accomplish this even without having the
requisite item creation feats.
    <p>
This type of improvement has nothing to do with the master�s character
level, though it may affect the item�s eventual <a style={{
        "color": "#579eb6"
      }} href="magicItemsICA.html#item-ego">Ego score</a>.
    </p>
    <h5><a id="inheriting-an-item-familiar"></a>
INHERITING AN ITEM FAMILIAR
    </h5>
Sometimes characters die, after which other characters pick up and use
their items; this turn of events is an integral part of the d20 game.
    <p>
When a character finds or somehow comes into possession of another
character�s item familiar, two results can occur.
    </p>
    <p><b><a id="an-ego-contest-ensues"></a>An Ego Contest Ensues:</b>
An item familiar normally resists being picked up and used by another
character. If the item familiar has an <a style={{
        "color": "#579eb6"
      }} href="magicItemsICA.html#item-ego">Ego score</a>, it automatically tries
to resist being wielded by anyone other than its linked master, even if
the link has been severed (possibly because the master is dead). The rules
for Ego conflicts can be found under <a style={{
        "color": "#579eb6"
      }} href="magicItemsICA.html#items-against-characters">Items
against Characters</a>. Normally, an intelligent item resists its owner
only when a conflict of personality or purpose ensues. An item familiar
assumes that any use of it by someone other than its linked (or previously
linked) master is a conflict, and so it resists every time the new owner
attempts to make use of it.
    </p>
    <p>
If the new owner wins the Ego contest, he can wield the item safely for 1
hour but cannot access any of the abilities the item familiar gained
through its link (such as invested XP, skill ranks, or spell
slots, or any special abilities it has that aren�t simply a feature of the
magic item). An item familiar may talk to its new owner (if it has that
capability), but it feels at best unfriendly and could be extraordinarily
hostile. If an item familiar�s alignment matches that of its new
owner, the item familiar may become less hostile over time but always
forces Ego contests when possible.
    </p>
    <p><b><a id="the-new-owner-can-attempt-a-link"></a>The New Owner Can Attempt a Link:</b>
The new owner can attempt to link to the item by selecting the <a style={{
        "color": "#579eb6"
      }} href="#item-familiar">Item
Familiar</a> feat (assuming he meets the prerequisite). What occurs next
depends on his character level compared to the character level of the
highest-level previous owner.
    </p>
    <p><i><a id="new-owner-is-same-or-higher-level"></a>New Owner Is Same or Higher Level:</i>
The link succeeds. The new owner gains all the benefits of the abilities
of the item familiar, and the item familiar�s alignment changes to match that of
its new master. If the item familiar had invested skill ranks and/or spell
slots, those investments become accessible to the new owner, adding to his
totals. If the item familiar has spell slots, the new master can access
them only if he could already cast spells of the appropriate levels.
    </p>
    <p><i><a id="new-owener-is-lower-level"></a>New Owner Is Lower Level:</i>
The link partially succeeds. The alignment of the item familiar
changes to match that of its new master, and the new owner can use all the
item familiar�s special abilities and powers. However, the new master
cannot benefit from any invested skill ranks or spell slots
until his character level equals or exceeds that of the item familiar�s
highest-level previous owner.
    </p>
    <h6><a id="true-inheritance"></a>
True Inheritance
    </h6>
A character may willingly pass on an item familiar. This transfer can be
accomplished while the owner is alive, or it can be stipulated as part of
a last will and testament if the owner dies. If a living character
willingly passes on an item familiar to a new owner with a matching
alignment, an <a style={{
      "color": "#579eb6"
    }} href="magicItemsICA.html#items-against-characters">Ego contest</a>
immediately ensues, but the new owner gains a +10 circumstance bonus on the
check. If the new owner wins, the item familiar does not force an Ego
contest again unless the new owner does something to violate his alignment
or to obstruct the item�s special purpose (if any). The item familiar does
not actually have a new master until the new owner selects the <a style={{
      "color": "#579eb6"
    }} href="#item-familiar">Item
Familiar</a> feat, but it cooperates with its new owner.
    <h5><a id="awakening-powers-and-abilities"></a>
AWAKENING POWERS AND ABILITIES
    </h5>
Rather than giving a player free rein to choose an item familiar�s special
abilities, the GM may decide to create items with "sleeping" abilities
that can only be awakened by having characters link themselves to them. In
such cases, the Game Master retains more control over what new abilities
and strange items get introduced into his game, but he does limit the
likelihood of characters taking the Item Creation feat.
    <p>
The GM may wish to mix and match awakening abilities with allowing
characters to choose new abilities for their familiars. This system can
support both concepts.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      